@import 'variables';

.navBar {
    height: 100px;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    justify-content: space-evenly;
    align-items: center;
    font-size: 1.2rem;
    z-index: 10;
    backdrop-filter: blur(50px);
    transition: opacity .3s ease-in-out;
    opacity: 1;
}

.menu-open {
    background-color: $primary-light;
}

.nav-scrolled {
    animation-name: fadeInNav;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

.show {
    display: flex;
}

.block {
    opacity: 0;
    max-height: 15px;
}

.scroll-title-hide {
    opacity: 0;
    /* animation-name: slideLeft;
    animation-duration: 1s;
    animation-fill-mode: forwards; */
}

.scroll-title {
    opacity: 1;
    /* animation-name: slideCenter;
    animation-duration: 1s;
    animation-fill-mode: forwards; */
}

@keyframes fadeInNav {
    from {
        height: 100px;
    }

    to {
        opacity: .5;
        height: 50px;
    }
}

@keyframes fadeOutNav {
    from {
        height: 50px;
        opacity: .7;
    }

    to {
        opacity: 1;
        height: 100px;
        box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.6);
    }
}

@keyframes slideUp {
    from {}

    to {
        height: 0 !important;
    }
}

@keyframes slideDown {
    from {}

    to {
        height: auto;
        opacity: 1;
    }
}

.scroll-show {
    opacity: .8;
}

.top-block {
    opacity: 0;
}

.nav-top {
    animation-name: fadeOutNav;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

.no-opacity {
    opacity: 1;
}

.nav-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.nav-title {
    padding: 10px;
    color: black;
    cursor: pointer;
    text-decoration: none;
    font-size: 1.5em;
    font-weight: bold;
}

.nav-title-left {
    padding: 10px;
    color: black;
    cursor: pointer;
    text-decoration: none;
    font-size: 1.5em;
    font-weight: bold;
}

.scroll-container {
    /* display: flex;
    width: 100%; */
}

.navMenu {
    width: 100%;
    flex-direction: row;
    float: right;
    justify-content: center;
    list-style: none;
    text-align: center;
    transition: height 1s ease-in-out, opacity 0.1s ease-in-out;
    backdrop-filter: blur(50px);
}

.nav-links {
    width: 100%;
    color: black;
    text-decoration: none;
    padding-bottom: 10px;
}

.subLinkDrop {
    position: absolute;
    top: 30px;
    width: 150px;
    animation-name: fadeInNav;
    animation-duration: 2s;
    animation-fill-mode: forwards;
}

.navItem:hover .subLinkDrop {
    display: block;
}

.navItem {
    position: relative;
    max-width: 175px;
    cursor: pointer;
    min-width: 120px;
}

.subLink {

    display: block;
    color: white;
}

.mobile-navButton {
    display: none;
    position: absolute;
    left: 10px;
    height: 40px;
    width: 40px;
    background-color: transparent;
    border: none;
    opacity: .8;
}

@media only screen and (max-width: 768px) {
    .mobile-navButton {
        display: block;
    }

    .nav-title {
        font-size: 1.2em;
    }

    .navBar {
        height: 100px;
    }

    .nav-top {}

    .navMenu {
        justify-content: center;
        align-items: center;
        padding-top: 15px;
        background: $primary-light;
        // backdrop-filter: blur(50px);

        display: none;
        width: 100%;
        flex-direction: column;
        height: 0;
        overflow: hidden;
        position: absolute;
        top: 100px;
        transition: height 1s ease-in-out, opacity 1s ease-in-out;
        opacity: 1;

    }

    .mobile-slide {
        animation-name: slideNav;
        animation-duration: 1s;
        animation-fill-mode: forwards;
        transition: height 1s ease-in-out, opacity 1s ease-in-out;

    }

    @keyframes slideNav {
        from {
            height: 0;
            opacity: 0;
        }

        to {
            height: auto;
            opacity: 1;
        }
    }

    .subLinkDrop {
        position: relative;
        top: 0;
    }

    .navItem {
        height: 50px;
        width: 100%;
        min-width: unset;
        max-width: none;

        a {
            width: 100%;
            backdrop-filter: blur(50px);
        }
    }

    .navBar {
        width: 100%;
    }
}

@media only screen and (min-width: 768px) {
    .navMenu {
        display: flex;
    }
}

.paypal-button {
    color: black;
    text-decoration: none;
    background-color: transparent;
    border: none;
    font-size: inherit;
}

.nav-links {
    display: inline-block;
    position: relative;
}

.nav-links:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #0087ca;
    transform-origin: bottom left;
}

.nav-links:hover:after {
    transform: scaleX(1);
    transform-origin: bottom right;
}