@import 'variables';

.homepage {
    font-family: 'Arial, sans-serif';
    color: #333;
    text-align: center;

    &__header {
        backdrop-filter: blur(50px);
        padding: 20px;
        color: white;
        display: flex;
        justify-content: space-between;
        align-items: center;

        backdrop-filter: &__logo {
            font-size: 24px;
            font-weight: bold;
        }

        &__nav ul {
            list-style: none;
            display: flex;
            gap: 15px;

            li a {
                color: $text-color-primary;
                text-decoration: none;
                font-weight: bold;
            }
        }
    }

    &__hero {
        background-color: $primary-light;
        // padding: 60px 20px;
        margin-top: 100px;
        color: $text-color-primary;
        display: flex;
        flex-direction: column;
        background-image: url('/images/svgs/hero.svg');
        background-size: cover;
        background-position: bottom;
        background-repeat: no-repeat;

        div {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

        }

        h1 {
            font-size: 48px;
            margin-bottom: 10px;
        }

        .logo {
            width: 250px;
        }

        p {
            color: $text-color-seconday;
            font-size: 20px;
            margin-bottom: 20px;
        }


        &__cta {
            background-color: #e74c3c;
            color: white;
            border: none;
            padding: 15px 30px;
            font-size: 16px;
            cursor: pointer;
            transition: background-color 0.3s;

            &:hover {
                background-color: #c0392b;
            }
        }

        img {
            width: 500px;
        }
    }

    &__content {
        padding-top: 90px;
        background-color: $secondary-dark;
        color: $text-color-seconday;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }

    &__section {
        padding: 20px;
        background-color: $primary-light;
        width: 100%;
        border-top-left-radius: 90% 20%;
        border-bottom-right-radius: 90% 40%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h2 {
            font-size: 32px;
            margin-bottom: 20px;
        }

        p {
            font-size: 18px;
            line-height: 1.6;
        }
    }

    &__footer {
        background-color: #333;
        color: white;
        padding: 20px;
        font-size: 14px;
    }

    .home-container {
        background-color: white;
        padding-left: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: left;
        width: auto;
        max-width: 800px;
        height: 100%;
        flex-direction: row;
        border-radius: 15px;
        opacity: .85;
        gap: 50px;
        margin-bottom: 50px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

        p {
            font-family: 'Times New Roman', serif;
            color: $text-color-primary;
            flex-direction: column;
            font-size: 20px;
            width: 50%;
            min-width: 325px;
            margin: 10px;
        }

        .image-button {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 50px;
        }

        .home-container-paragraph {
            margin: 15px;
        }
    }

    .btn {
        display: inline-block;
        padding: 12px 24px;
        border: none;
        border-radius: 4px;
        background-color: rgb(255, 170, 151);
        color: #333;
        font-size: 16px;
        text-align: center;
        text-decoration: none;
        cursor: pointer;
        transition: box-shadow 0.3s;
        box-shadow: 0 0 0 transparent;
        transition: background-color 0.5s;
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .btn:hover {
        box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
        background-color: rgb(255, 203, 184);
    }

    .btn:not(:hover) {
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .jenny {
        height: 300px;
        width: 180px;
        margin: 50px;
        margin-left: 0;
        margin-bottom: 0;
        margin-right: 0;
        border-radius: 11px;
    }

    @media only screen and (max-width: 768px) {
        .home-container {
            margin-top: 15px;
            max-width: 350px;
            margin-bottom: 50px;
            padding-top: 15px;
            padding-bottom: 15px;
            flex-direction: column;
            gap: 10px;
        }

        .home-container p {
            margin-right: 0;
            margin-left: 0;
        }

        &__hero {
            img {
                width: 100%;
            }
        }

        &__content {
            padding-top: 0;
        }
    }
}