@import 'variables';

.phoneNumber {
    white-space: nowrap;
}

.additionalResources {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
}

.info {
    padding-top: 36px;
    padding-bottom: 36px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: white;
    opacity: .8;
}

.info p {
    font-style: italic;
    font-size: 12px;
    color: #323236;
    max-width: 800px;
}

.alert{
    font-weight: bold;
    color: red;
    font-size: 20px;
    max-width: 800px;
}

table {
    margin: 8px;
    border-radius: 8px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
td {
     padding: 4px;
     max-width: 500px;
}
.additionalResources h2{
    color:white;

}
// h2 {
//     margin: 24px;
// }

.table-row:nth-child(even) {background: #F5F2F2 }
.table-row:nth-child(odd) {background: #FFF}

.info:nth-child(even){
    background-color: #F5F2F2;
}