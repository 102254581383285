@import 'variables';

.packs{   
    margin: 5px;
    align-items: center;
    text-align: center;
    padding: 8px;
}
.programs-container{
    padding-top: 100px;
    position: relative;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-family: 'Times New Roman', serif;
    background-color: $secondary-dark;
    /* background-image: url('../images/homepageBackground.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: relative;
    background-attachment: fixed; */
}
.programs-container h1{
    font-size: 29px;
}
.pack-container{
    background-color: white;
    width: 100%;
    max-width: 1000px;
    border-radius: 15px;;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    text-align: left;
    justify-content: center;
    margin-bottom: 50px;
    padding: 50px 50px 50px 100px;
}
.text-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    // align-items: center;
    text-align: left;
    gap: 20px;
    max-width: 500px;

    ul {
        margin-left: 25px;
    }
}
.list{
    text-align: left;
    width: unset !important;
    max-width: unset;
}
.center-text{
    text-align: center;
}
.positive-pack-info{
    justify-content: center;
}
.pack-container p{
    justify-content: center;
    align-self: l;
    margin-bottom: 20px;
    font-size: 18px;
}
.emoji-container{
    background-color: transparent;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 8px;
    justify-content: center;
    margin-bottom: 50px;
}
.emoji-header{
    font-size: 29px;
    margin-top: 25px;
    color: black;
    padding: 4px;
    opacity: .8;
    margin-bottom: 25px;

}
.carousel {
    margin: unset !important;
}
.pack-content-row{
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 50px;
}
.image{
    width:100%;
    max-width: 300px;
    height:100;
    max-height: 195px;
}
.programs{
    align-items: center;
    text-align: center;
}
.grant{
    display: flex;
    flex-direction: row;
    gap: 50px
}
// tr:nth-child(even) {background: rgb(255, 234, 200)}
// tr:nth-child(odd) {background: #FFF}

.pack-container:nth-child(even){
    background-color: $primary-light;
}
@media only screen and (max-width: 768px) {
    .pack-content-row {
        display: flex;
        flex-direction: column;
    }
    .grant{
        display: flex;
        flex-direction: column;
    }
    .pack-container p{
        width: 100%;
    }
    .pack-container{
    padding: 10px 10px 10px 30px;
    }
}